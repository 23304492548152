import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css`
        .checkout {
            margin-top: 2em;

            .date-panel {
                margin: 3em auto 1em auto;
                padding-bottom: 1em;
                border-bottom: 1px solid #333333;
                width: 100%;
                max-width: 30em;

                .columns {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;

                    .column {
                        padding: 0 .5em;
                        text-align: center;

                        .column-label {
                            color: #666666;
                            font-size: 1.2em;
                            margin-bottom: .5em;
                        }

                        .column-value {
                            color: ${variables.dpc_fontDark};
                            font-size: 1.4em;
                        }
                    }
                }
                .submit {
                    a {
                        margin: 2em auto 1em auto;
                        display: block;
                        text-align: center;
                        color: #df3c46;
                        font-weight: 600;
                        cursor: pointer;
                    }
                }
            }

            .headline {
                font-size: 3em;
                color: ${variables.dpc_fontDark};
                font-weight: 900;
                line-height: 90%;
                text-align: center;
                margin-top: 1em;
            }

            .form {
                width: 100%;
                max-width: 30em;
                margin: 2em auto;

                .regulations-container {
                    margin-top: 2em;
                    font-size: 1.2em;
                }
                
                .login-link {
                    color: #ff0000;
                    font-size: 1em;
                    font-weight: 600;
                    margin: 1em 0;
                    margin-bottom: 2em;
                    display: block;
                    text-decoration: underline;
                    cursor: pointer;
                }

                .disclaimer {
                    display: inline-block;
                    width: 100%;
                    height: 3em;
                    color: #666;
                    font-size: .7em;
                    line-height: 1.5em;
                    white-space: pre-wrap;
                    overflow: hidden;

                    &.expandDisclaimer {
                        height: auto;
                    }
                }
                
                .show-more-text {
                    display: inline-block;
                    color: ${variables.dpc_fontDark};
                    cursor: pointer;
                    border-bottom: 1px solid #b7b7b7;
                    margin-top: 1em;
                    font-size: .75em;
                }

                > .component-button {
                    margin-top: 2em;
                }

                .userNoteTextArea {
                    margin: 1em 0;
                }
            }
        }

        .input-external-wrapper {
            .form-error-message-component {
                color: #ff1414;
            }
        }

        @media (max-width: ${variables.tabletL}) {
            .checkout {
                .headline {
                    font-size: 2.6em;
                }
            }
        }

        @media (max-width: ${variables.mobileL}) {
            .checkout {
                .headline {
                    font-size: 2.4em;
                }
            }
        }
    `;
