import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import classnames from 'classnames';
import { toast } from 'react-toastify';

import { ADEPT_DASHBOARD, PUBLIC_HOME, PUBLIC_RESERVATION_CONFIRMATION } from 'Consts/routes';
import { LOCAL_STORAGE_LEAD_SURVEY } from 'Consts/localStorage';
import { PUBLIC_RESERVATIONS_CHECKOUT_FORM } from 'Consts/components';
import { API_RESOURCE_REDIRECT_URI } from 'Consts/apiResources';
import { LEAD_SOURCE_RESERVATIONS, LEAD_MEDIUM_SHOP } from 'Consts/leads';

import { getItem } from 'Services/LocalStorage';
import Logger from 'Services/Logger';

import { translate } from 'Utils/language';
import { toApiFormat } from 'Utils/date';
import { parseQueryToObject } from 'Utils/querystring';
import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import Button from 'Components/layout/Button';
import Form from 'Components/forms/Form';
import Input from 'Components/forms/Input';
import Checkbox from 'Components/forms/Checkbox';
import Textarea from 'Components/forms/Textarea';

moment.locale('pl');

export default class PublicReservationsCheckoutForm extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object,
        actions: PropTypes.shape({
            couponCheck: PropTypes.func.isRequired,
            getProduct: PropTypes.func.isRequired,
            reservation: PropTypes.func.isRequired,
            refreshToken: PropTypes.func.isRequired,
        }).isRequired,

        selectedOffer: PropTypes.object.isRequired,
        selectedLocation: PropTypes.object.isRequired,
        selectedProduct: PropTypes.object.isRequired,
        selectedSlot: PropTypes.shape({
            slot: PropTypes.shape({
                time: PropTypes.string.isRequired,
                trainerId: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,

        onResetTraining: PropTypes.func,
        showComment: PropTypes.bool,
        showPromoCode: PropTypes.bool,
        initialState: PropTypes.object,
        currentLanguage: PropTypes.string,
        onFormStateChange: PropTypes.func.isRequired,
        data: PropTypes.object.isRequired,
        utmData: PropTypes.object,
        showRoomNumber: PropTypes.bool,
        phoneNumberRequired: PropTypes.bool,
        promoCodeRequired: PropTypes.bool,
        redirectToConfirmationPage: PropTypes.bool,
        reservationSlug: PropTypes.string,
    };
    static defaultProps = {
        onResetTraining: null,
        showComment: false,
        showPromoCode: false,
        showRoomNumber: false,
        phoneNumberRequired: true,
        promoCodeRequired: false,
        initialState: {},
        redirectToConfirmationPage: false,
        reservationSlug: null,
    };
    state = {
        formState: {
            name: null,
            surname: null,
            email: null,
            phone: null,
            discountCode: null,
            consentFitadeptTerms: this.props.data && this.props.data.consentFitadeptTerms || false, //eslint-disable-line react/destructuring-assignment
            consentMarketingPhone: this.props.data && this.props.data.consentMarketingPhone || false, //eslint-disable-line react/destructuring-assignment
            consentMarketingEmail: this.props.data && this.props.data.consentMarketingEmail || false, //eslint-disable-line react/destructuring-assignment
            ...this.props.initialState, //eslint-disable-line react/destructuring-assignment
            expandDisclaimer: false,
            selectAll: false,
        },
        formError: null,
        formErrors: {},
    };
    rcReservationSlugs = [
        'rc-masaze',
        'rc-masaze-now',
    ];
    sobieskiReservationSlugs = [
        'sobieski-masaze',
    ];
    reservationSlugs = [
        ...this.rcReservationSlugs,
        ...this.sobieskiReservationSlugs,
    ]

    componentDidUpdate = (prevProps, prevState) => {
        const { formState } = this.state;

        if (formState.selectAll && !prevState.formState.selectAll) { //eslint-disable-line react/destructuring-assignment
            this.setState({
                formState: {
                    ...formState,
                    consentFitadeptTerms: true,
                    consentMarketingPhone: true,
                    consentMarketingEmail: true,
                },
            });
        }

        if (!formState.selectAll && prevState.formState.selectAll) { //eslint-disable-line react/destructuring-assignment
            this.setState({
                formState: {
                    ...formState,
                    consentFitadeptTerms: false,
                    consentMarketingPhone: false,
                    consentMarketingEmail: false,
                },
            });
        }
    }

    componentDidMount = () => {
        const { data, reservationSlug } = this.props;
        const { location, match } = this.props;
        const { search } = location;
        const queryObject = parseQueryToObject(search, true);

        window.scrollTo(0, 0);

        const leadData = getItem(LOCAL_STORAGE_LEAD_SURVEY);
        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                name: leadData?.name || prevState.formState.name,
                surname: leadData?.surname || prevState.formState.surname,
                email: this.reservationSlugs.includes(reservationSlug)
                    ? this.generateRCDynamicEmail()
                    : leadData?.email || prevState.formState.email,
                phone: leadData?.phone || prevState.formState.phone,
            },
        }));

        const getComment = () => {
            if (match && match?.params?.code) {
                return match.params.code;
            }

            if (queryObject.code) {
                return queryObject.code;
            }

            return '';
        };

        const getPromoCode = () => {
            if (queryObject.code) {
                return queryObject.code;
            }

            return '';
        };

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                ...data || null,
                comment: getComment(),
                promoCode: getPromoCode(),
            },
        }));
    }

    onStateChange = (name, value) => {
        const { onFormStateChange } = this.props;
        const { formState } = this.state;

        this.setState(prevState => ({
            formState: {
                ...prevState.formState,
                [name]: value,
            },
        }), () => {
            onFormStateChange({
                ...formState,
                [name]: value,
            });
        });
    };

    onSubmit = () => {
        const { 
            actions, 
            selectedOffer, 
            selectedLocation, 
            selectedProduct, 
            selectedSlot, 
            history, 
            utmData, 
            phoneNumberRequired, 
            promoCodeRequired,
            redirectToConfirmationPage,
            reservationSlug,
        } = this.props;
        const { formState } = this.state;

        this.setState({
            isPending: true,
            formErrors: {},
        }, () => {
            const data = {
                ...formState,
                user: {
                    name: formState.name,
                    surname: formState.surname,
                    phone: formState.phone,
                    email: formState.email,
                },
                consents: [{
                    type: 'terms',
                    content: 'Przeczytałem i akceptuję regulamin sklepu',
                    value: formState.consentFitadeptTerms,
                }, {
                    type: 'marketingEmail',
                    content: 'Wyrażam zgodę na otrzymywanie informacji handlowych dotyczących towarów lub usług FitAdept na wskazany przeze mnie adres e-mail drogą elektroniczną.',
                    value: formState.consentMarketingEmail,
                }, {
                    type: 'phone',
                    content: 'Wyrażam zgodę na przekazywanie mi treści marketingowych FitAdept sp. z o.o. za pomocą telekomunikacyjnych urządzeń końcowych, których jestem użytkownikiem, w szczególności na podany przeze mnie numer telefonu.',
                    value: formState.consentMarketingPhone,
                }],
                leadId: selectedSlot && selectedSlot.slot && selectedSlot.slot.trainerId,
                events: [{
                    startAt: selectedSlot && selectedSlot.slot && toApiFormat(selectedSlot.slot.time),
                    productId: selectedProduct.id,
                }],
                comment: formState.comment || undefined,
                locationId: selectedLocation.id,
                offerId: selectedOffer.id,
                promoCode: formState.promoCode,
                medium: LEAD_MEDIUM_SHOP,
                source: LEAD_SOURCE_RESERVATIONS,
                phoneNumberRequired,
                promoCodeRequired,
                name: formState.name,
                surname: formState.surname,
                phone: formState.phone,
                email: formState.email,
                ...(utmData || {}),
            };

            actions.reservation(data)
                .then(response => new Promise((resolve, reject) => {
                    const result = response.payload;

                    if (result.authToken) {
                        actions.refreshToken({ authToken: result.authToken })
                            .then(() => resolve(result))
                            .catch(error => {
                                Logger.log('Reservations refresh token error', {
                                    error,
                                });
                            });
                    } else {
                        toast('Takie konto już istnieje, zaloguj się');
                        resolve(result);
                    }
                }))
                .then(result => {
                    if (result[API_RESOURCE_REDIRECT_URI]) {
                        window.location.href = result[API_RESOURCE_REDIRECT_URI];
                    } else if (redirectToConfirmationPage && reservationSlug) {
                        history.push(
                            withVariables(
                                PUBLIC_RESERVATION_CONFIRMATION.path,
                                { id: result.userOffer.id, slug: reservationSlug },
                                { productId: selectedProduct.id }
                            )
                        );
                    } else {
                        history.push(ADEPT_DASHBOARD.path);
                    }

                    this.setState({ isPending: false });
                })
                .catch(error => {
                    const validationErrors = {};
                    if (error && error.payload && error.payload.validationErrors) {
                        Object.keys(error.payload.validationErrors).forEach(innerError => {
                            validationErrors[innerError] = error.payload.validationErrors[innerError];
                        });

                        Object.keys(error.payload.validationErrors).map(fieldName => {                       
                            error.payload.validationErrors[fieldName].map(errorMessage => {
                                validationErrors[fieldName.replace('user.', '')] = validationErrors[fieldName];

                                if(fieldName === 'consents.0') {
                                    toast.error(errorMessage);
                                }
                            });
                        });
                    }

                    this.setState(prevState => ({
                        isPending: false,
                        formErrors: {
                            ...prevState.formErrors,
                            ...validationErrors,
                        },
                    }));
                });
        });
    }

    generateRCDynamicEmail = () => {
        const { reservationSlug } = this.props;

        const date = moment().format('YYYY.MM.DD');
        const time = moment().format('HH.mm.ss');
        let prefix = 'RC';

        if (this.sobieskiReservationSlugs.includes(reservationSlug)) {
            prefix = 'Hotel';
        }

        return `${prefix}+${date}+${time}@fitadept.com`;
    }

    render() {
        const { 
            selectedLocation, 
            selectedSlot, 
            onResetTraining, 
            showComment, 
            currentLanguage, 
            location, 
            history, 
            showPromoCode, 
            showRoomNumber, 
            phoneNumberRequired,
            promoCodeRequired,
        } = this.props;
        const { formState, formError, formErrors, isPending, expandDisclaimer } = this.state;
       
        return (
            <StyledComponent
                className="component-public-reservations-checkout-form"
                styles={require('./styles')}
            >
                <div className="checkout">
                    <div className="date-panel">
                        <div className="columns">
                            <div className="column column-location">
                                <h3 className="column-label">
                                    {translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_datePanel_columnLocation_label`, currentLanguage, location)}
                                </h3>
                                <span className="column-value">
                                    {selectedLocation.name}
                                </span>
                            </div>
                            <div className="column column-date">
                                <h3 className="column-label">
                                    {translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_datePanel_columnDate_label`, currentLanguage, location)}
                                </h3>
                                <span className="column-value">
                                    {moment(selectedSlot.slot.time).format('DD.MM.YYYY')}
                                </span>
                            </div>
                            <div className="column column-time">
                                <h3 className="column-label">
                                    {translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_datePanel_columnTime_label`, currentLanguage, location)}
                                </h3>
                                <span className="column-value">
                                    {moment.utc(selectedSlot.slot.time).local()
                                        .format('HH:mm')}
                                </span>
                            </div>
                        </div>
                        {onResetTraining && (
                            <div className="submit">
                                <a onClick={onResetTraining}>
                                    {translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_datePanel_submit_label`, currentLanguage, location)}
                                </a>
                            </div>
                        )}
                    </div>
                    <h1 className="headline">
                        {translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_headline`, currentLanguage, location)}
                    </h1>
                    <div className="form">
                        <Form
                            data={formState}
                            onStateChange={this.onStateChange}
                            errors={formErrors}
                            globalError={formError}
                            onSubmit={this.onSubmit}
                            isPending={isPending}
                        >
                            <Input
                                label={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_name`, currentLanguage, location)}
                                name="name"
                                placeholder={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_name`, currentLanguage, location)}
                                required={true}
                                styleVersion={2}
                                style='transparentV2'
                                border='light'
                            />
                            <Input
                                label={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_surname`, currentLanguage, location)}
                                name="surname"
                                placeholder={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_surname`, currentLanguage, location)}
                                required={true}
                                styleVersion={2}
                                style='transparentV2'
                                border='light'
                            />
                            <Input
                                label={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_email`, currentLanguage, location)}
                                name="email"
                                placeholder={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_email`, currentLanguage, location)}
                                type="email"
                                required={true}
                                styleVersion={2}
                                style='transparentV2'
                                border='light'
                                value='asd@asd.pl'
                            />
                            {formErrors && formErrors.email && (
                                <a
                                    className="login-link"
                                    onClick={() => history.push(PUBLIC_HOME.path)}
                                >
                                    Masz już konto? Kliknij tutaj, aby się zalogować.
                                </a>
                            )}
                            <Input
                                label={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_phone`, currentLanguage, location)}
                                name="phone"
                                placeholder={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_phone`, currentLanguage, location)}
                                required={phoneNumberRequired}
                                styleVersion={2}
                                style='transparentV2'
                                border='light'
                            />
                            {showComment && !showPromoCode && (
                                <Input
                                    label={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_discountCode`, currentLanguage, location)}
                                    name="comment"
                                    placeholder={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_discountCode`, currentLanguage, location)}
                                    required={false}
                                    styleVersion={2}
                                    style='transparentV2'
                                    border='light'
                                />
                            )}
                            {showPromoCode && !showComment && (
                                <Input
                                    label={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_discountCode`, currentLanguage, location)}
                                    name="promoCode"
                                    placeholder={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_discountCode`, currentLanguage, location)}
                                    required={promoCodeRequired}
                                    styleVersion={2}
                                    style='transparentV2'
                                    border='light'
                                />
                            )}
                            {showRoomNumber && (
                                <Input
                                    label={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_roomNumber`, currentLanguage, location)}
                                    name="roomName"
                                    placeholder={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_roomNumber`, currentLanguage, location)}
                                    styleVersion={2}
                                    style='transparentV2'
                                    border='light'
                                />
                            )}
                            <Button
                                type="submit"
                                size="extraLarge"
                                layout="fullWidth"
                                styleVersion={2}
                                onClick={this.onSubmit}
                                disabled={isPending}
                            >
                                {translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_form_input_submit`, currentLanguage, location)}
                            </Button>

                            <div className="regulations-container">
                                {formErrors && formErrors['consents.0'] && (
                                    <a className="login-link">
                                        Zaakceptuj regulamin sklepu.
                                    </a>
                                )}
                                <Checkbox
                                    label={`${translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_regulations-container_consentSelectAll`, currentLanguage, location)}`}
                                    name="selectAll"
                                    styleVersion={1}
                                />
                                <Checkbox
                                    label={`${translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_regulations-container_consentFitadeptTerms`, currentLanguage, location)} <a href='https://fitadept.com/regulamin' target='_blank'> ${translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_regulations-container_consentFitadeptTerms_more`, currentLanguage, location)} </a>`}
                                    name="consentFitadeptTerms"
                                    styleVersion={1}
                                />
                                <Checkbox
                                    label={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_regulations-container_consentMarketingEmail`, currentLanguage, location)}
                                    name="consentMarketingEmail"
                                    styleVersion={1}
                                />
                                <Checkbox
                                    label={translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_regulations-container_consentMarketingPhone`, currentLanguage, location)}
                                    name="consentMarketingPhone"
                                    styleVersion={1}
                                />
                                <span className={classnames({
                                    ['disclaimer']: true,
                                    ['expandDisclaimer']: expandDisclaimer,
                                })}>
                                    {translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_regulations-container_disclaimer`, currentLanguage, location)}
                                </span>
                                <a
                                    className="show-more-text"
                                    onClick={() => this.setState(prevState => ({
                                        expandDisclaimer: !prevState.expandDisclaimer,
                                    }))}
                                >
                                    {expandDisclaimer 
                                        ? translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_regulations-container_less`, currentLanguage, location)
                                        : translate(`${PUBLIC_RESERVATIONS_CHECKOUT_FORM}_checkout_regulations-container_more`, currentLanguage, location)}
                                </a>
                                {expandDisclaimer && (
                                    <Textarea
                                        className="userNoteTextArea"
                                        name="notes"
                                        label="Notatka do zamówienia"
                                        placeholder="Notatka do zamówienia"
                                        styleVersion={1}
                                        style='transparentV2'
                                    />
                                )}
                            </div>
                        </Form>
                    </div>
                </div>
            </StyledComponent>
        );
    }
}
